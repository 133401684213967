import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import './NewsDigest.css'

const NewsDigest = (props) => {
    const { excerpt, frontmatter, id, internal, fields } = props.node;
    
    const wordsPerMinute = 500; // Average case.
    let minRead
    let textLength = internal.content.length
        minRead = Math.round(textLength / wordsPerMinute)
    return (
        <React.Fragment>
            <li className="post-list-item" key={id}>
                <Link to={fields.slug}>
                    <div class="flex-container" >
                        <div class="padding-list">
                        <Img  fixed={frontmatter.featuredImage.childImageSharp.fixed} 
                          alt={frontmatter.title}
                          />
                        </div>
                        <div class="">
                            <div className="post-list-text"><strong>{frontmatter.title}</strong></div>
                            <div className="post-list-text font-kokoro">{frontmatter.categories[0].category}{' | '}{minRead}{' '}min{' '}read{' | '}{frontmatter.date}</div>
                            <div className="post-list-desc">{excerpt}</div>
                        </div>
                    {/* <img className="post-list-img" src={frontmatter.cover} alt={frontmatter.title}/> */}
                    
                    </div>
                </Link>
          </li>
        </React.Fragment>
    )
}


export default NewsDigest

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "images/blog/schema_seo.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `

