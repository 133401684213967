import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Layout from '../components/Layout'
import NewsDigest from '../components/NewsDigest'
import './HomePage.css'


// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, subtitle, posts, postTechInfo, postGatsby, cover}) => {

  let postArray = posts.edges.map((post) => {
       
    return (
      <NewsDigest {...post}/>
    )
  })

  
  let postArrayGatsby = postGatsby.edges.map((post) => {
    
        return (
          <NewsDigest {...post}/>
        )

    
  })

  let postArrayTechInfo = postTechInfo.edges.map((post) => {
       
        return (
          <NewsDigest {...post}/>
        )
  })

  return (
    <main className="Home">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={cover}
    />

    <section className="section">
      <div className="container">
        <h3><strong>最新のアメリカのウェブプログラミングニュース＋ブログ</strong></h3><br />
        <p>フラミンゴッドニュースはアメリカで日常的に使われている最先端のウェブ開発技術情報をわかりやすくプログラマーだけでなく、IT企業に属する方々全ての方に情報を共有します。イギリスとアメリカの留学経験と就職、またアメリカでビジネス今日を受けた経験を元に、何故アメリカがITビジネスで成功していて、日本語今後成功していく為には何が必要なのか、ウェブ開発者の視点、また起業家の視点から情報を送り届けます。
        </p>
        <h4>最新のブログ10件</h4>
      <ul className="post-list">{postArray}</ul>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h4>最新のGatsbyJSに関してのブログ10件</h4>
      <ul className="post-list">{postArrayGatsby}</ul>
      </div>
    </section>
    <section className="section">
      <div className="container">最新のアメリカテクノロジーブログ10件
      <ul className="post-list">{postArrayTechInfo}</ul>
      </div>
    </section>
    
  </main>
  )

}

// Export Default HomePage for front-end
const HomePage = ({ data: { data, posts, postTechInfo, postGatsby } }) => {
  
  return (
    <Layout meta={data.frontmatter.meta || false}
            template={data.frontmatter.template || false}
            datePosted={data.frontmatter.date || false}
            featuredImage={data.frontmatter.featuredImage || false}
    >
      
      <HomePageTemplate {...data} {...data.frontmatter} body={data.html} posts={posts} postTechInfo={postTechInfo} postGatsby={postGatsby} />
      
    </Layout>
  )

}

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    data: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        cover      
      }
    }
    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 10
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            # date
            categories {
              category
            }
            featuredImage{
              childImageSharp{
                fixed(width: 100, height: 100) {
                ...GatsbyImageSharpFixed
              }
              }
            }
          }
          internal{
            content
          }
        }
      }
    }
    postTechInfo: allMarkdownRemark(
      filter: {frontmatter: {categories: {elemMatch: {category: {eq: "Tech Info"}}}}, 
      fields: {contentType: {eq: "posts"}}}
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          internal {
            content
          }
          frontmatter {
            categories {
              category
            }
            featuredImage{
              childImageSharp{
                fixed(width: 100, height: 100) {
                ...GatsbyImageSharpFixed
              }
              }
            }
            date
            title
          }
        }
      }
    }
    postGatsby: allMarkdownRemark(
      filter: {frontmatter: {categories: {elemMatch: {category: {eq: "Gatsbyチュートリアル"}}}}, 
      fields: {contentType: {eq: "posts"}}}
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          internal {
            content
          }
          frontmatter {
            categories {
              category
            }
            cover
            featuredImage{
              childImageSharp{
                fixed(width: 100, height: 100) {
                ...GatsbyImageSharpFixed
              }
              }
            }
            date
            title
          }
        }
      }
    }
  }
`


